import Vue from 'vue'
import Router from 'vue-router'
const Authorization = () => import(/* webpackChunkName: "user" */ 'app/components/Authorization')
const Cabinet = () => import(/* webpackChunkName: "content" */ 'app/components/Cabinet')
const Catalog = () => import(/* webpackChunkName: "catalog" */ 'app/components/Catalog')
const Order = () => import(/* webpackChunkName: "catalog" */ 'app/components/Order')
const Contacts = () => import(/* webpackChunkName: "content" */ 'app/components/Contacts')
const Rules = () => import(/* webpackChunkName: "rules" */ 'app/components/Rules')
const AdminUserEdit = () => import(/* webpackChunkName: "admin" */ 'app/components/AdminUserEdit')
const AdminUsersList = () => import(/* webpackChunkName: "admin" */ 'app/components/AdminUsersList')
const AdminOrdersList = () => import(/* webpackChunkName: "admin" */ 'app/components/AdminOrdersList')
const AdminOrdersStatistics = () => import(/* webpackChunkName: "admin" */ 'app/components/AdminOrdersStatistics')
const AdminTransactions = () => import(/* webpackChunkName: "admin" */ 'app/components/AdminTransactions')
const AdminTransactionsUpload = () => import(/* webpackChunkName: "admin" */ 'app/components/AdminTransactionsUpload')

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Cabinet',
      component: Cabinet,
      meta: {
        title: 'Личный кабинет участника',
        permissions: {
          roles: [
            'user',
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/auth',
      name: 'Authorization',
      component: Authorization,
      meta: {
        title: 'Авторизация',
        permissions: {
          roles: [
            'guest'
          ],
          path: '/cabinet'
        }
      }
    },
    {
      path: '/catalog',
      name: 'Catalog',
      component: Catalog,
      meta: {
        title: 'Выберите подарок',
        permissions: {
          roles: [
            'user',
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/rules',
      name: 'Rules',
      component: Rules,
      meta: {
        title: 'Правила программы',
        permissions: {
          roles: [
            'user',
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/order',
      name: 'Order',
      component: Order,
      meta: {
        title: 'Оформление заказа',
        permissions: {
          roles: [
            'user',
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/contacts',
      name: 'Contacts',
      component: Contacts,
      meta: {
        title: 'Контакты',
        permissions: {
          roles: [
            '*'
          ]
        }
      }
    },
    {
      path: '/admin/user/:userId',
      name: 'AdminUserEdit',
      component: AdminUserEdit,
      meta: {
        title: 'Редактирование пользователя',
        permissions: {
          roles: [
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/admin/users/list',
      name: 'AdminUsersList',
      component: AdminUsersList,
      meta: {
        title: 'Список пользователей',
        permissions: {
          roles: [
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/admin/orders/list',
      name: 'AdminOrdersList',
      component: AdminOrdersList,
      meta: {
        title: 'Список заказов',
        permissions: {
          roles: [
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/admin/orders/statistics',
      name: 'AdminOrdersStatistics',
      component: AdminOrdersStatistics,
      meta: {
        title: 'Статистика заказов',
        permissions: {
          roles: [
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/admin/transactions/list',
      name: 'AdminTransactions',
      component: AdminTransactions,
      meta: {
        title: 'Транзакции',
        permissions: {
          roles: [
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '/admin/transactions/upload',
      name: 'AdminTransactionsUpload',
      component: AdminTransactionsUpload,
      meta: {
        title: 'Загрузка транзакций',
        permissions: {
          roles: [
            'manager',
            'admin'
          ]
        }
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})
